import React from 'react';
import './Footer.css'
import JD from '../../image/jAndD2.png';

const Footer = () => {
    return (
        <footer className='App-footer'>
            <img className='jd-footer-logo' src={JD} />
        <p>&copy; 2025 JD Software. All rights reserved.</p>
        </footer>
    )
}

export default Footer;