import React from "react";
import "./About.css";
import "../project_samples/Projects.css";
import useScreenSize from "../useScreenSize";
import Footer from "../components/footer/Footer";

const About = ({ image }) => {
  const [awards, mdb] = image;
  const isMobile = useScreenSize("(max-width: 768px)");
  console.log(isMobile);

  return (
    <>
    <div className="Content">
      <div className={isMobile ? "upper-section" : "upper-section-mobile"}>
        <h1 className="title-heading">About Me</h1>
        <div className="project-sample-container">
          <h1 className="paragraph">
            I am a React web and mobile app developer specializing in
            creating responsive, user-friendly applications. As a
            full-stack developer, I bring ideas to life by managing the entire
            development process from concept to launch.
          </h1>
          <div className="image-container2">
            <a
              href="https://university.mongodb.com/certification/certificate/470238744"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={mdb} alt="profilePic" />
            </a>
          </div>
        </div>
        <div className="project-sample-container">
          <h1 className="paragraph">
            I specialize in Mobile Device Management (MDM) as a JAMF System
            Administrator. I develop comprehensive migration plans for JAMF and
            software applications to enhance endpoint security. I oversee MacOS and Windows imaging processes using JAMF and Intune to streamline employee onboarding.
          </h1>
          <div className="image-container2">
            <img src={awards} alt="profilePic" />
          </div>
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default About;
