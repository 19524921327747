import React from "react";
import './Resume.css'
import pdfUrl from '../pdf/softwaredeveloper.pdf'

const Resume = () => {

  return (
    <div className="Content">
      <div className="upper-section">
        <h1 className="tossing">Resume</h1>
        <div className="project-sampler-container">
          <iframe src={pdfUrl} width="100%" height="1400px" title="Resume PDF">
            This browser does not support PDFs. Please download the PDF to view
            it: <a href={pdfUrl}>Download PDF</a>.
          </iframe>
        </div>
      </div>
    </div>
  );
};

export default Resume;
